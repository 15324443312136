import gql from "graphql-tag";

export default gql`
  mutation addCreditCommentary(
    $security_id: String!
    $security_parsekey: String!
    $effective_time: String!
    $category: String!
    $detail: String!
    $current: Boolean!
    $user: String!
  ) {
    addCreditCommentary(
      security_id: $security_id
      security_parsekey: $security_parsekey
      effective_time: $effective_time
      category: $category
      detail: $detail
      current: $current
      user: $user
    ) {
      __typename
      id
      security_id
      security_parsekey
      effective_time
      category
      detail
      current
      user
    }
  }
`;
