import gql from "graphql-tag";

export default gql`
  mutation updateCreditCommentary($id: String!, $updates: [UpdateInput!]!) {
    updateCreditCommentary(id: $id, updates: $updates) {
      __typename
      id
      security_id
      security_parsekey
      effective_time
      category
      detail
      current
      user
    }
  }
`;
