import gql from "graphql-tag";

export default gql`
  query getLuPsa {
    getLuPsa {
      __typename
      code
      code_parent
      name
      level
      local_level
      population
      path
      depth
    }
  }
`;
