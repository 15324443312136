import gql from "graphql-tag";

export default gql`
  query getSecurity($query: QueryInput, $offset: OffsetInput) {
    getSecurity(query: $query, offset: $offset) {
      __typename
      id
      entity_id
      parsekey
      isin
      cusip
      sedol
      cik
      short_name
      long_name
      description
      bbg_ticker
      bbg_composite_exchange
      bbg_yellow_key
      currency
    }
  }
`;
