import gql from "graphql-tag";

export default gql`
  query getRating($query: QueryInput, $offset: OffsetInput) {
    getRating(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_date
      ambc
      bbg
      dbrs
      ejr
      fitch
      kbra
      moodys
      sp
    }
  }
`;
