import gql from "graphql-tag";

export default gql`
  query getFundamentalSignal($query: QueryInput, $offset: OffsetInput) {
    getFundamentalSignal(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      year
      quarter
      earnings_date
      report_date
      net_ppe_to_market_cap
      gross_ppe_to_market_cap
      net_ppe_to_enterprise_value
      gross_ppe_to_enterprise_value
      net_interest_expense_to_30pct_ebitda
      net_interest_expense_to_30pct_ebit
      revenue_to_capital_expenditures
      total_debt_to_equity
      net_debt_to_equity
      total_debt_to_ebitda
      net_debt_to_ebitda
      land_and_buildings_to_market_cap
      land_and_buildings_to_enterprise_value
    }
  }
`;
