import gql from "graphql-tag";

export default gql`
  mutation updateProperty($id: String!, $updates: [UpdateInput!]!) {
    updateProperty(id: $id, updates: $updates) {
      __typename
      id
      asset_mgmt_id
      name
      type
      subtype
      emissions_type
      naics_code
      address_id
      address_raw
      address
      geo_code
      psa_code
      latitude
      longitude
      parcel
      buildings
      square_feet
      land_acres
      parking_spaces
      office_fraction
      construction_year
      renovation_years
      tags
      notes
    }
  }
`;
