import gql from "graphql-tag";

export default gql`
  query getHolder($query: QueryInput, $offset: OffsetInput) {
    getHolder(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      name
      type
      security_id_holder
      security_parsekey_holder
      country
      market_value
      percent_outstanding
      position
      position_change
      holding_period
      cost_basis
      total_return_est
      filing_date
      filing_source
    }
  }
`;
