import gql from "graphql-tag";

export default gql`
  mutation addDocumentLinked(
    $link_type: String!
    $link_type_id: String!
    $cloud_path: String!
    $sha_256_hash: String!
    $upload_name: String
    $normalized_name: String
  ) {
    addDocumentLinked(
      link_type: $link_type
      link_type_id: $link_type_id
      cloud_path: $cloud_path
      sha_256_hash: $sha_256_hash
      upload_name: $upload_name
      normalized_name: $normalized_name
    ) {
      __typename
      document_link_id
      document_id
      link_type
      link_type_id
      cloud_path
      sha_256_hash
      upload_name
      normalized_name
    }
  }
`;
