import gql from "graphql-tag";

export default gql`
  mutation addProperty(
    $asset_mgmt_id: String!
    $name: String!
    $type: String
    $subtype: String
    $emissions_type: String
    $naics_code: String
    $address_id: String
    $address_raw: String
    $address: String
    $geo_code: String
    $psa_code: String
    $latitude: String
    $longitude: String
    $parcel: String
    $buildings: String
    $square_feet: String
    $land_acres: String
    $parking_spaces: String
    $office_fraction: String
    $construction_year: String
    $renovation_years: String
    $tags: String
    $notes: String
  ) {
    addProperty(
      asset_mgmt_id: $asset_mgmt_id
      name: $name
      type: $type
      subtype: $subtype
      emissions_type: $emissions_type
      naics_code: $naics_code
      address_id: $address_id
      address_raw: $address_raw
      address: $address
      geo_code: $geo_code
      psa_code: $psa_code
      latitude: $latitude
      longitude: $longitude
      parcel: $parcel
      buildings: $buildings
      square_feet: $square_feet
      land_acres: $land_acres
      parking_spaces: $parking_spaces
      office_fraction: $office_fraction
      construction_year: $construction_year
      renovation_years: $renovation_years
      tags: $tags
      notes: $notes
    ) {
      __typename
      id
      asset_mgmt_id
      name
      type
      subtype
      emissions_type
      naics_code
      address_id
      address_raw
      address
      geo_code
      psa_code
      latitude
      longitude
      parcel
      buildings
      square_feet
      land_acres
      parking_spaces
      office_fraction
      construction_year
      renovation_years
      tags
      notes
    }
  }
`;
