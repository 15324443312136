import gql from "graphql-tag";

export default gql`
  mutation addCrmActivityLink($crm_activity_id: String!, $crm_type: String!, $crm_type_id: String!) {
    addCrmActivityLink(crm_activity_id: $crm_activity_id, crm_type: $crm_type, crm_type_id: $crm_type_id) {
      __typename
      crm_activity_link_id
      crm_activity_id
      crm_type
      crm_type_id
      effective_time
      action
      detail
      user
    }
  }
`;
