import gql from "graphql-tag";

export default gql`
  query getCreditDaily($query: QueryInput, $offset: OffsetInput) {
    getCreditDaily(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_date
      cds_spread_5y
      cds_spread_bb_5y
      prob_default_bb_1y
      prob_default_bb_5y
      ivol_1m_neg1sigma
      ivol_3m_neg1sigma
      ivol_6m_neg1sigma
      ivol_12m_neg1sigma
    }
  }
`;
