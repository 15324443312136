import gql from "graphql-tag";

export default gql`
  mutation updateValuation($id: String!, $updates: [UpdateInput!]!) {
    updateValuation(id: $id, updates: $updates) {
      __typename
      id
      property_id
      date
      cap_rate
      notes
    }
  }
`;
