import gql from "graphql-tag";

export default gql`
  query getPeer($query: QueryInput, $offset: OffsetInput) {
    getPeer(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      security_id_peer
      security_parsekey_peer
      peer_rank
    }
  }
`;
