import gql from "graphql-tag";

export default gql`
  mutation updateCrmActivity($id: String!, $updates: [UpdateInput!]!) {
    updateCrmActivity(id: $id, updates: $updates) {
      __typename
      crm_activity_link_id
      crm_activity_id
      crm_type
      crm_type_id
      effective_time
      action
      detail
      user
    }
  }
`;
