import gql from "graphql-tag";

export default gql`
  query getESG($query: QueryInput, $offset: OffsetInput) {
    getESG(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_date
      bbg_total_disclosure
      bbg_env_disclosure
      bbg_social_disclosure
      bbg_gov_disclosure
      sp_total_pctile
      sp_env_pctile
      sp_social_pctile
      sp_gov_pctile
      cdp_env
      iss_gov
      msci_total
      sustainalytics_total_risk
    }
  }
`;
