import gql from "graphql-tag";

export default gql`
  query getDebt($query: QueryInput, $offset: OffsetInput) {
    getDebt(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      security_parsekey_debt
      maturity_date
      coupon
      amount_outstanding
    }
  }
`;
