import gql from "graphql-tag";

export default gql`
  query getDocumentLinkedByEntity(
    $link_type: String!
    $link_type_id: String!
    $query: QueryInput
    $offset: OffsetInput
  ) {
    getDocumentLinkedByEntity(link_type: $link_type, link_type_id: $link_type_id, query: $query, offset: $offset) {
      __typename
      document_link_id
      document_id
      link_type
      link_type_id
      cloud_path
      sha_256_hash
      upload_name
      normalized_name
    }
  }
`;
