import gql from "graphql-tag";

export default gql`
  query getTenantTaskStatus($query: QueryInput, $offset: OffsetInput) {
    getTenantTaskStatus(query: $query, offset: $offset) {
      __typename
      id
      tenant_task_id
      effective_period
      due_date
      complete_date
      complete_docs
      complete_note
      review_date
      review_status
      review_note
      review_user
    }
  }
`;
