import gql from "graphql-tag";

export default gql`
  query getUser($query: QueryInput, $offset: OffsetInput) {
    getUser(query: $query, offset: $offset) {
      __typename
      id
      name
      short_name
      email
      bloomberg_uuid
      groups
      reports
    }
  }
`;
