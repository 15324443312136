import gql from "graphql-tag";

export default gql`
  query getOptimizedCreditComposite($query: QueryInput, $offset: OffsetInput) {
    getOptimizedCreditComposite(query: $query, offset: $offset) {
      __typename
      e_id
      e_lei
      e_typ
      e_pnt
      e_sid
      e_spk
      e_sn
      e_ln
      e_des
      e_rf
      e_rc
      e_rp
      e_ceo
      e_ceod
      e_cfo
      e_cfod
      e_url
      e_irn
      e_irt
      e_ire
      e_irp
      e_egic
      e_sec
      e_igp
      e_ind
      e_subi
      e_geo
      e_ct
      e_st
      e_cty
      r_s_am
      r_l_am
      r_lw_am
      r_o_am
      r_l_bbg
      r_lw_bbg
      r_s_db
      r_l_db
      r_lw_db
      r_o_db
      r_l_ej
      r_s_fch
      r_sw_fch
      r_l_fch
      r_lw_fch
      r_o_fch
      r_l_kb
      r_o_kb
      r_s_md
      r_sw_md
      r_l_md
      r_lw_md
      r_o_md
      r_s_sp
      r_sw_sp
      r_l_sp
      r_lw_sp
      r_o_sp
      c_cds
      c_cds_bb
      c_pd1_bb
      c_pd5_bb
      c_iv1
      c_iv3
      c_iv6
      c_iv12
      c_az
      c_azp
      c_azpp
      er_rp
      er_rd
      er_rt
      er_cd
      er_ct
      er_cn
      er_cp
      er_crn
      er_crp
      er_cru
      s_nhp
      s_nhr
      s_ns
      s_nsp
      s_nsz
      s_nsn
      s_ts
      s_tsp
      s_tsz
      s_tsn
    }
  }
`;
