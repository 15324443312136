import gql from "graphql-tag";

export default gql`
  query getAnnouncement($query: QueryInput, $offset: OffsetInput) {
    getAnnouncement(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_date
      ma
      jv_spin
      buyback
    }
  }
`;
