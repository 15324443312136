import gql from "graphql-tag";

export default gql`
  query getLuRating {
    getLuRating {
      __typename
      code
      code_parent
      name
      level
      score
      ambc_lt
      bbg_lt
      dbrs_lt
      fitch_lt
      moodys_lt
      sp_lt
      path
      depth
    }
  }
`;
