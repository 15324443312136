import gql from "graphql-tag";

export default gql`
  query getCrmActivityLinked($query: QueryInput, $offset: OffsetInput) {
    getCrmActivityLinked(query: $query, offset: $offset) {
      __typename
      crm_activity_link_id
      crm_activity_id
      crm_type
      crm_type_id
      effective_time
      action
      detail
      user
    }
  }
`;
