import gql from "graphql-tag";

export default gql`
  mutation updatePipeline($id: String!, $updates: [UpdateInput!]!) {
    updatePipeline(id: $id, updates: $updates) {
      __typename
      id
      deal
      category
      status
      status_date
      detail
      current
      last
      user
    }
  }
`;
