import gql from "graphql-tag";

export default gql`
  query getLease($query: QueryInput, $offset: OffsetInput) {
    getLease(query: $query, offset: $offset) {
      __typename
      id
      purchase_id
      property_id
      asset_mgmt_id
      oak_entity_code
      tenant_raw
      tenant_parsekey
      guarantor_raw
      guarantor_parsekey
      industry_raw
      initial_credit_rating_code
      initial_credit_rating
      initial_credit_rating_detail
      type
      currency
      start_date_orig
      start_date_ar
      start_date_rent
      end_date
      rent_escalation
      rent_escalation_detail
      renewal_options
      renewal_options_base_rent
      termination_option
      termination_option_detail
      transfer_terms
      tenant_purchase_options
      master_lease
      tags
      notes
    }
  }
`;
