import gql from "graphql-tag";

export default gql`
  query getRatingDetail($query: QueryInput, $offset: OffsetInput) {
    getRatingDetail(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_date
      ambc_st
      ambc_lt
      ambc_lt_watch
      ambc_outlook
      bbg_lt
      bbg_lt_watch
      dbrs_st
      dbrs_lt
      dbrs_lt_watch
      dbrs_outlook
      ejr_lt
      fitch_st
      fitch_st_watch
      fitch_lt
      fitch_lt_watch
      fitch_outlook
      kbra_lt
      kbra_outlook
      moodys_st
      moodys_st_watch
      moodys_lt
      moodys_lt_watch
      moodys_outlook
      sp_st
      sp_st_watch
      sp_lt
      sp_lt_watch
      sp_outlook
    }
  }
`;
