import gql from "graphql-tag";

export default gql`
  mutation deleteCrmActivityLink($id: String!) {
    deleteCrmActivityLink(id: $id) {
      __typename
      crm_activity_link_id
      crm_activity_id
      crm_type
      crm_type_id
      effective_time
      action
      detail
      user
    }
  }
`;
