import { getAurora } from "../graphql";

export const reloadQuery = async (apolloClient, params) => {
  const validParams = apolloClient && params.gqlQuery;
  if (!validParams) {
    console.log("Missing required params.");
    return;
  }

  const gqlName = params.gqlQuery.definitions[0].name.value;
  const gqlVariables = params.gqlVariables ? params.gqlVariables : {};
  const batchSize = params.batchSize ? params.batchSize : 500;

  if (params.countSql) {
    let count = await apolloClient.query({
      query: getAurora,
      variables: {
        query: params.countSql,
      },
      fetchPolicy: "no-cache",
    });
    count = JSON.parse(count.data.getAurora)[0].Count;

    const completionQueryCount = Math.ceil(count / batchSize);

    const promises = [...Array(completionQueryCount).keys()].map((i) => {
      let variables;
      if (gqlName === "getAurora") {
        variables = {
          query: gqlVariables.query + " LIMIT " + batchSize + " OFFSET " + i * batchSize,
        };
      } else {
        variables = {
          ...gqlVariables,
          query: {
            ...gqlVariables.query,
          },
          offset: { start: 1 + i * batchSize, end: (i + 1) * batchSize },
        };
      }
      return apolloClient.query({
        query: params.gqlQuery,
        variables: variables,
        fetchPolicy: "network-only",
      });
    });
    try {
      await Promise.all(promises);
    } catch (e) {
      console.log(e);
    }
  } else {
    await apolloClient.query({
      query: params.gqlQuery,
      variables: gqlVariables,
      fetchPolicy: "network-only",
    });
  }
};
