import gql from "graphql-tag";

export default gql`
  mutation updateCrmStatus($crm_type: String!, $crm_type_id: String!, $updates: [UpdateInput!]!) {
    updateCrmStatus(crm_type: $crm_type, crm_type_id: $crm_type_id, updates: $updates) {
      __typename
      crm_type
      crm_type_id
      crm_name
      status
      assigned_to
      third_parties
      last_touch_time
      last_touch_action
      last_touch_detail
      last_touch_user
      last_user_touch_time
      last_user_touch_action
      last_user_touch_detail
      last_user_touch_user
    }
  }
`;
