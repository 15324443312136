import gql from "graphql-tag";

export default gql`
  mutation updateTenantTask($id: String!, $updates: [UpdateInput!]!) {
    updateTenantTask(id: $id, updates: $updates) {
      __typename
      id
      task
      property
      category
      reference_doc
      reference_doc_section
      required_docs
      effective_period
      due_dates
      due_dates_rrule
      contact_email
      notify_email
      user
      archived
    }
  }
`;
