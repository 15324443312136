import gql from "graphql-tag";

export default gql`
  mutation updateFunding($id: String!, $updates: [UpdateInput!]!) {
    updateFunding(id: $id, updates: $updates) {
      __typename
      id
      purchase_id
      property_id
      oak_entity_code
      oak_tranche_code
      equity_initial_fund_usd
      equity_initial_sidecar_usd
      lender
      lender_contact
      currency
      initial_amount
      last_amount
      start_date
      io_expiry_date
      maturity_date
      amortization_period
      extension_options
      prepayment_fees
      prepayment_fees_detail
      benchmark
      loan_spread
      swap_mid
      credit_spread
      swap_mtm
      tags
      notes
    }
  }
`;
