import gql from "graphql-tag";

export default gql`
  query getSecurityProxy($query: QueryInput, $offset: OffsetInput) {
    getSecurityProxy(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      security_id_proxy
      security_parsekey_proxy
      rank
    }
  }
`;
