import gql from "graphql-tag";

export default gql`
  mutation addCrmActivityLinked(
    $crm_type: String!
    $crm_type_id: String!
    $effective_time: String!
    $action: String!
    $detail: String
    $user: String!
  ) {
    addCrmActivityLinked(
      crm_type: $crm_type
      crm_type_id: $crm_type_id
      effective_time: $effective_time
      action: $action
      detail: $detail
      user: $user
    ) {
      __typename
      crm_activity_link_id
      crm_activity_id
      crm_type
      crm_type_id
      effective_time
      action
      detail
      user
    }
  }
`;
