import gql from "graphql-tag";

export default gql`
  query getEstimate($query: QueryInput, $offset: OffsetInput) {
    getEstimate(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_date
      revenue_mean
      revenue_sd
      revenue_n
      ebitda_mean
      ebitda_sd
      ebitda_n
      pretax_income_mean
      pretax_income_sd
      pretax_income_n
      net_income_mean
      net_income_sd
      net_income_n
      earnings_per_share_mean
      earnings_per_share_sd
      earnings_per_share_n
      dividends_per_share_mean
      dividends_per_share_sd
      dividends_per_share_n
      return_on_equity_mean
      return_on_equity_sd
      return_on_equity_n
    }
  }
`;
