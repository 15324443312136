import gql from "graphql-tag";

export default gql`
  mutation updateRent($id: String!, $updates: [UpdateInput!]!) {
    updateRent(id: $id, updates: $updates) {
      __typename
      id
      purchase_id
      property_id
      oak_entity_code
      date
      base_rent
      expansion_rent
      management_fee
    }
  }
`;
