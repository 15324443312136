import gql from "graphql-tag";

export default gql`
  mutation updateCrmPerson($id: String!, $updates: [UpdateInput!]!) {
    updateCrmPerson(id: $id, updates: $updates) {
      __typename
      id
      first_name
      last_name
      title
      affiliation
      email
      office_phone
      mobile_phone
      asset_focus
      coverage_focus
      user
    }
  }
`;
