import gql from "graphql-tag";

export default gql`
  mutation updateCrmIntermediary($id: String!, $updates: [UpdateInput!]!) {
    updateCrmIntermediary(id: $id, updates: $updates) {
      __typename
      id
      name
      type
      user
    }
  }
`;
