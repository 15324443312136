import gql from "graphql-tag";

export default gql`
  query getCrmActivityLinkedByEntity(
    $crm_type: String!
    $crm_type_id: String!
    $query: QueryInput
    $offset: OffsetInput
  ) {
    getCrmActivityLinkedByEntity(crm_type: $crm_type, crm_type_id: $crm_type_id, query: $query, offset: $offset) {
      __typename
      crm_activity_link_id
      crm_activity_id
      crm_type
      crm_type_id
      effective_time
      action
      detail
      user
    }
  }
`;
