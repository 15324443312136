import React from "react";
import { Route, Routes } from "react-router-dom";
import Lazy from "./shared/LazyHOC";
import { UnauthenticatedTemplate, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

const LazyHome = Lazy(React.lazy(() => import("./containers/Home")));

const LazyNotFound = Lazy(React.lazy(() => import("./containers/NotFound")));

const LazyMarketMonitor = Lazy(React.lazy(() => import("./containers/MarketMonitor")));

const LazyActivityMonitor = Lazy(React.lazy(() => import("./containers/ActivityMonitor")));

const LazyCreditMonitor = Lazy(React.lazy(() => import("./containers/CreditMonitor")));

const LazyPortfolio = Lazy(React.lazy(() => import("./containers/Portfolio")));

const LazyOwlBot = Lazy(React.lazy(() => import("./containers/OwlBot")));

const LazyTenantPortal = Lazy(React.lazy(() => import("./containers/TenantPortal")));

function RouterRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LazyHome />} />

      <Route path="*" element={<LazyNotFound />} />

      <Route
        path="/tenant-portal/:key"
        element={
          <UnauthenticatedTemplate>
            <LazyTenantPortal />
          </UnauthenticatedTemplate>
        }
      />

      <Route
        path="/market-monitor/:view"
        element={
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
            <LazyMarketMonitor />
          </MsalAuthenticationTemplate>
        }
      />
      <Route
        path="/market-monitor/:view/:parsekey"
        element={
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
            <LazyMarketMonitor />
          </MsalAuthenticationTemplate>
        }
      />

      <Route
        path="/activity-monitor/:view"
        element={
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
            <LazyActivityMonitor />
          </MsalAuthenticationTemplate>
        }
      />

      <Route
        path="/credit-monitor/:view"
        element={
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
            <LazyCreditMonitor />
          </MsalAuthenticationTemplate>
        }
      />
      <Route
        path="/credit-monitor/:view/:parsekey"
        element={
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
            <LazyCreditMonitor />
          </MsalAuthenticationTemplate>
        }
      />

      <Route
        path="/portfolio/:view"
        element={
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
            <LazyPortfolio />
          </MsalAuthenticationTemplate>
        }
      />

      <Route
        path="/owl-bot"
        element={
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
            <LazyOwlBot />
          </MsalAuthenticationTemplate>
        }
      />
    </Routes>
  );
}

export default RouterRoutes;
