import gql from "graphql-tag";

export default gql`
  query getRent($query: QueryInput, $offset: OffsetInput) {
    getRent(query: $query, offset: $offset) {
      __typename
      id
      purchase_id
      property_id
      oak_entity_code
      date
      base_rent
      expansion_rent
      management_fee
    }
  }
`;
