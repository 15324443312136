import gql from "graphql-tag";

export default gql`
  mutation deletePipeline($id: String!) {
    deletePipeline(id: $id) {
      __typename
      id
      deal
      category
      status
      status_date
      detail
      current
      last
      user
    }
  }
`;
