import gql from "graphql-tag";

export default gql`
  mutation updateDueDiligence($id: String!, $updates: [UpdateInput!]!) {
    updateDueDiligence(id: $id, updates: $updates) {
      __typename
      id
      purchase_id
      property_id
      oak_entity_code
      reporting_req
      property_manager
      estoppel
      snda
      rofr_rofo
      go_dark
      landlord_maintenance
      tenant_maintenance
      tax_freq
      tax_payer
      tax_authorities
      tax_tenant_contact
      tax_due_dates
      tax_url
      store_number
      tags
      notes
    }
  }
`;
