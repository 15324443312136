import gql from "graphql-tag";

export default gql`
  query getLuEGics {
    getLuEGics {
      __typename
      code
      code_parent
      name
      bbg_subgroups
      level
      description
      path
      depth
    }
  }
`;
