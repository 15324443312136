import gql from "graphql-tag";

export default gql`
  mutation addPurchase(
    $property_id: String!
    $asset_mgmt_id: String!
    $oak_entity_code: String
    $oak_tranche_code: String
    $status: String
    $date: String
    $currency: String
    $price: String
    $price_usd: String
    $closing_costs: String
    $noi: String
    $source: String
    $competition: String
    $sourced_by: String
    $market_rent_psf: String
    $tags: String
    $notes: String
  ) {
    addPurchase(
      property_id: $property_id
      asset_mgmt_id: $asset_mgmt_id
      oak_entity_code: $oak_entity_code
      oak_tranche_code: $oak_tranche_code
      status: $status
      date: $date
      currency: $currency
      price: $price
      price_usd: $price_usd
      closing_costs: $closing_costs
      noi: $noi
      source: $source
      competition: $competition
      sourced_by: $sourced_by
      market_rent_psf: $market_rent_psf
      tags: $tags
      notes: $notes
    ) {
      __typename
      id
      property_id
      asset_mgmt_id
      oak_entity_code
      oak_tranche_code
      status
      date
      currency
      price
      price_usd
      closing_costs
      noi
      source
      competition
      sourced_by
      market_rent_psf
      tags
      notes
    }
  }
`;
