import gql from "graphql-tag";

export default gql`
  mutation addCrmIntermediary($name: String!, $type: String!, $user: String!) {
    addCrmIntermediary(name: $name, type: $type, user: $user) {
      __typename
      id
      name
      type
      user
    }
  }
`;
