import gql from "graphql-tag";

export default gql`
  mutation addLease(
    $purchase_id: String!
    $property_id: String!
    $asset_mgmt_id: String!
    $oak_entity_code: String
    $tenant_raw: String
    $tenant_parsekey: String
    $guarantor_raw: String
    $guarantor_parsekey: String
    $industry_raw: String
    $initial_credit_rating_code: String
    $initial_credit_rating: String
    $initial_credit_rating_detail: String
    $type: String
    $currency: String
    $start_date_orig: String
    $start_date_ar: String
    $start_date_rent: String
    $end_date: String
    $rent_escalation: String
    $rent_escalation_detail: String
    $renewal_options: String
    $renewal_options_base_rent: String
    $termination_option: String
    $termination_option_detail: String
    $transfer_terms: String
    $tenant_purchase_options: String
    $master_lease: String
    $tags: String
    $notes: String
  ) {
    addLease(
      purchase_id: $purchase_id
      property_id: $property_id
      asset_mgmt_id: $asset_mgmt_id
      oak_entity_code: $oak_entity_code
      tenant_raw: $tenant_raw
      tenant_parsekey: $tenant_parsekey
      guarantor_raw: $guarantor_raw
      guarantor_parsekey: $guarantor_parsekey
      industry_raw: $industry_raw
      initial_credit_rating_code: $initial_credit_rating_code
      initial_credit_rating: $initial_credit_rating
      initial_credit_rating_detail: $initial_credit_rating_detail
      type: $type
      currency: $currency
      start_date_orig: $start_date_orig
      start_date_ar: $start_date_ar
      start_date_rent: $start_date_rent
      end_date: $end_date
      rent_escalation: $rent_escalation
      rent_escalation_detail: $rent_escalation_detail
      renewal_options: $renewal_options
      renewal_options_base_rent: $renewal_options_base_rent
      termination_option: $termination_option
      termination_option_detail: $termination_option_detail
      transfer_terms: $transfer_terms
      tenant_purchase_options: $tenant_purchase_options
      master_lease: $master_lease
      tags: $tags
      notes: $notes
    ) {
      __typename
      id
      purchase_id
      property_id
      asset_mgmt_id
      oak_entity_code
      tenant_raw
      tenant_parsekey
      guarantor_raw
      guarantor_parsekey
      industry_raw
      initial_credit_rating_code
      initial_credit_rating
      initial_credit_rating_detail
      type
      currency
      start_date_orig
      start_date_ar
      start_date_rent
      end_date
      rent_escalation
      rent_escalation_detail
      renewal_options
      renewal_options_base_rent
      termination_option
      termination_option_detail
      transfer_terms
      tenant_purchase_options
      master_lease
      tags
      notes
    }
  }
`;
