import gql from "graphql-tag";

export default gql`
  mutation updateCustomFilter($id: String!, $updates: [UpdateInput!]!) {
    updateCustomFilter(id: $id, updates: $updates) {
      __typename
      id
      user_id
      type
      name
      definition
    }
  }
`;
