import gql from "graphql-tag";

export default gql`
  query getCreditCommentaryByEntity($security_id: String!, $query: QueryInput, $offset: OffsetInput) {
    getCreditCommentaryByEntity(security_id: $security_id, query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_time
      category
      detail
      current
      user
    }
  }
`;
