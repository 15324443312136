import gql from "graphql-tag";

export default gql`
  mutation addPipeline(
    $deal: String!
    $category: String!
    $status: String!
    $status_date: String!
    $detail: String!
    $current: Boolean!
    $last: Boolean!
    $user: String!
  ) {
    addPipeline(
      deal: $deal
      category: $category
      status: $status
      detail: $detail
      status_date: $status_date
      current: $current
      last: $last
      user: $user
    ) {
      __typename
      id
      deal
      category
      status
      detail
      status_date
      current
      last
      user
    }
  }
`;
