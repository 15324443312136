import gql from "graphql-tag";

export default gql`
  query getSale($query: QueryInput, $offset: OffsetInput) {
    getSale(query: $query, offset: $offset) {
      __typename
      id
      purchase_id
      property_id
      oak_entity_code
      oak_tranche_code
      status
      date
      currency
      price
      price_usd
      closing_costs
      broker_fees
      noi_loi
      noi_loi_ftm
      noi_closing
      noi_closing_ftm
      buyer
      buyer_type
      gross_moic
      gross_irr
      net_moic
      net_irr
      gross_moic_ex_swaps
      gross_irr_ex_swaps
      net_moic_ex_swaps
      net_irr_ex_swaps
      tags
      notes
    }
  }
`;
