import gql from "graphql-tag";

export default gql`
  query getScoring($query: QueryInput, $offset: OffsetInput) {
    getScoring(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_date
      sale_leaseback_score
      real_estate_concentration
      depressed_valuation
      interest_coverage
      capex_coverage
      activist_campaign
      strategic_review
      ma_activity
      new_management
      breaking_news
    }
  }
`;
