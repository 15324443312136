import gql from "graphql-tag";

export default gql`
  mutation addDocumentLink($document_id: String!, $link_type: String!, $link_type_id: String!) {
    addDocumentLink(document_id: $document_id, link_type: $link_type, link_type_id: $link_type_id) {
      __typename
      document_link_id
      document_id
      link_type
      link_type_id
      cloud_path
      sha_256_hash
      upload_name
      normalized_name
    }
  }
`;
