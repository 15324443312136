import gql from "graphql-tag";

export default gql`
  query getTelemetry($query: QueryInput, $offset: OffsetInput) {
    getTelemetry(query: $query, offset: $offset) {
      __typename
      id
      user_id
      action
      value
    }
  }
`;
