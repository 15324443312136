import gql from "graphql-tag";

export default gql`
  mutation deleteCreditCommentary($id: String!) {
    deleteCreditCommentary(id: $id) {
      __typename
      id
      security_id
      security_parsekey
      effective_time
      category
      detail
      current
      user
    }
  }
`;
