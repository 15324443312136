import gql from "graphql-tag";

export default gql`
  mutation addFunding(
    $purchase_id: String!
    $property_id: String!
    $oak_entity_code: String
    $oak_tranche_code: String
    $equity_initial_fund_usd: String
    $equity_initial_sidecar_usd: String
    $lender: String
    $lender_contact: String
    $currency: String
    $initial_amount: String
    $last_amount: String
    $start_date: String
    $io_expiry_date: String
    $maturity_date: String
    $amortization_period: String
    $extension_options: String
    $prepayment_fees: String
    $prepayment_fees_detail: String
    $benchmark: String
    $loan_spread: String
    $swap_mid: String
    $credit_spread: String
    $swap_mtm: String
    $tags: String
    $notes: String
  ) {
    addFunding(
      purchase_id: $purchase_id
      property_id: $property_id
      oak_entity_code: $oak_entity_code
      oak_tranche_code: $oak_tranche_code
      equity_initial_fund_usd: $equity_initial_fund_usd
      equity_initial_sidecar_usd: $equity_initial_sidecar_usd
      lender: $lender
      lender_contact: $lender_contact
      currency: $currency
      initial_amount: $initial_amount
      last_amount: $last_amount
      start_date: $start_date
      io_expiry_date: $io_expiry_date
      maturity_date: $maturity_date
      amortization_period: $amortization_period
      extension_options: $extension_options
      prepayment_fees: $prepayment_fees
      prepayment_fees_detail: $prepayment_fees_detail
      benchmark: $benchmark
      loan_spread: $loan_spread
      swap_mid: $swap_mid
      credit_spread: $credit_spread
      swap_mtm: $swap_mtm
      tags: $tags
      notes: $notes
    ) {
      __typename
      id
      purchase_id
      property_id
      oak_entity_code
      oak_tranche_code
      equity_initial_fund_usd
      equity_initial_sidecar_usd
      lender
      lender_contact
      currency
      initial_amount
      last_amount
      start_date
      io_expiry_date
      maturity_date
      amortization_period
      extension_options
      prepayment_fees
      prepayment_fees_detail
      benchmark
      loan_spread
      swap_mid
      credit_spread
      swap_mtm
      tags
      notes
    }
  }
`;
