import gql from "graphql-tag";

export default gql`
  query getRatingDefaultRate($query: QueryInput, $offset: OffsetInput) {
    getRatingDefaultRate(query: $query, offset: $offset) {
      __typename
      id
      rating
      year
      rate
    }
  }
`;
