import gql from "graphql-tag";

export default gql`
  query getCrmStatus($query: QueryInput, $offset: OffsetInput) {
    getCrmStatus(query: $query, offset: $offset) {
      __typename
      crm_type
      crm_type_id
      crm_name
      status
      assigned_to
      third_parties
      last_touch_time
      last_touch_action
      last_touch_detail
      last_touch_user
      last_user_touch_time
      last_user_touch_action
      last_user_touch_detail
      last_user_touch_user
    }
  }
`;
