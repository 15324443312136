import gql from "graphql-tag";

export default gql`
  query getAudit($query: QueryInput, $offset: OffsetInput) {
    getAudit(query: $query, offset: $offset) {
      __typename
      id
      user
      table
      primary_key
      field
      value
      notes
      created_time
    }
  }
`;
