import gql from "graphql-tag";

export default gql`
  query getCatalyst($query: QueryInput, $offset: OffsetInput) {
    getCatalyst(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_date
      catalyst_score
      rating_downgrade
      depressed_valuation
      stock_price_drop
      real_estate_concentration
      new_management
      ma_activity
      activist_campaign
      share_buyback
      debt_rollover
      strategic_review
      capex_announcement
      underfunded_pension
      interest_deductability
      nol_carryforward
      tax_arbitrage
    }
  }
`;
