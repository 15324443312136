import gql from "graphql-tag";

export default gql`
  query getLuGeo {
    getLuGeo {
      __typename
      code
      code_parent
      name
      iso_code
      bbg_code
      level
      local_level
      path
      depth
    }
  }
`;
