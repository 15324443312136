import gql from "graphql-tag";

export default gql`
  mutation addCrmPerson(
    $first_name: String!
    $last_name: String!
    $title: String
    $affiliation: String
    $email: String
    $office_phone: String
    $mobile_phone: String
    $asset_focus: String
    $coverage_focus: String
    $user: String!
  ) {
    addCrmPerson(
      first_name: $first_name
      last_name: $last_name
      title: $title
      affiliation: $affiliation
      email: $email
      office_phone: $office_phone
      mobile_phone: $mobile_phone
      asset_focus: $asset_focus
      coverage_focus: $coverage_focus
      user: $user
    ) {
      __typename
      id
      first_name
      last_name
      title
      affiliation
      email
      office_phone
      mobile_phone
      asset_focus
      coverage_focus
      user
    }
  }
`;
