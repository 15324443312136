import gql from "graphql-tag";

export default gql`
  mutation addRent(
    $purchase_id: String!
    $property_id: String!
    $oak_entity_code: String
    $date: String!
    $base_rent: String
    $expansion_rent: String
    $management_fee: String
  ) {
    addRent(
      purchase_id: $purchase_id
      property_id: $property_id
      oak_entity_code: $oak_entity_code
      date: $date
      base_rent: $base_rent
      expansion_rent: $expansion_rent
      management_fee: $management_fee
    ) {
      __typename
      id
      purchase_id
      property_id
      oak_entity_code
      date
      base_rent
      expansion_rent
      management_fee
    }
  }
`;
