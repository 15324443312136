import gql from "graphql-tag";

export default gql`
  mutation addTelemetry($user_id: String!, $action: String!, $value: String) {
    addTelemetry(user_id: $user_id, action: $action, value: $value) {
      __typename
      id
      user_id
      action
      value
    }
  }
`;
