import gql from "graphql-tag";

export default gql`
  query getLuNaics {
    getLuNaics {
      __typename
      code
      code_parent
      name
      level
      description
      path
      depth
    }
  }
`;
