import gql from "graphql-tag";

export default gql`
  query getPipeline($query: QueryInput, $offset: OffsetInput) {
    getPipeline(query: $query, offset: $offset) {
      __typename
      id
      deal
      category
      status
      status_date
      detail
      current
      last
      user
    }
  }
`;
