import gql from "graphql-tag";

export default gql`
  mutation addDueDiligence(
    $purchase_id: String!
    $property_id: String!
    $oak_entity_code: String
    $reporting_req: String
    $property_manager: String
    $estoppel: String
    $snda: String
    $rofr_rofo: String
    $go_dark: String
    $landlord_maintenance: String
    $tenant_maintenance: String
    $tax_freq: String
    $tax_payer: String
    $tax_authorities: String
    $tax_tenant_contact: String
    $tax_due_dates: String
    $tax_url: String
    $store_number: String
    $tags: String
    $notes: String
  ) {
    addDueDiligence(
      purchase_id: $purchase_id
      property_id: $property_id
      oak_entity_code: $oak_entity_code
      reporting_req: $reporting_req
      property_manager: $property_manager
      estoppel: $estoppel
      snda: $snda
      rofr_rofo: $rofr_rofo
      go_dark: $go_dark
      landlord_maintenance: $landlord_maintenance
      tenant_maintenance: $tenant_maintenance
      tax_freq: $tax_freq
      tax_payer: $tax_payer
      tax_authorities: $tax_authorities
      tax_tenant_contact: $tax_tenant_contact
      tax_due_dates: $tax_due_dates
      tax_url: $tax_url
      store_number: $store_number
      tags: $tags
      notes: $notes
    ) {
      __typename
      id
      purchase_id
      property_id
      oak_entity_code
      reporting_req
      property_manager
      estoppel
      snda
      rofr_rofo
      go_dark
      landlord_maintenance
      tenant_maintenance
      tax_freq
      tax_payer
      tax_authorities
      tax_tenant_contact
      tax_due_dates
      tax_url
      store_number
      tags
      notes
    }
  }
`;
