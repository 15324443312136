import gql from "graphql-tag";

export default gql`
  mutation addTenantTask(
    $task: String!
    $property: String!
    $category: String!
    $reference_doc: String
    $reference_doc_section: String
    $required_docs: String
    $effective_period: String
    $due_dates: String
    $due_dates_rrule: String
    $contact_email: String
    $notify_email: String
    $user: String!
    $archived: Boolean!
  ) {
    addTenantTask(
      task: $task
      property: $property
      category: $category
      reference_doc: $reference_doc
      reference_doc_section: $reference_doc_section
      required_docs: $required_docs
      effective_period: $effective_period
      due_dates: $due_dates
      due_dates_rrule: $due_dates_rrule
      contact_email: $contact_email
      notify_email: $notify_email
      user: $user
      archived: $archived
    ) {
      __typename
      id
      task
      property
      category
      reference_doc
      reference_doc_section
      required_docs
      effective_period
      due_dates
      due_dates_rrule
      contact_email
      notify_email
      user
      archived
    }
  }
`;
