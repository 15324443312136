import gql from "graphql-tag";

export default gql`
  query getFundamental($query: QueryInput, $offset: OffsetInput) {
    getFundamental(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      year
      period_type
      period
      period_basis
      fiscal_period
      earnings_date
      report_date
      market_cap
      enterprise_value
      total_equity
      revenue
      ebitda
      ebit
      nol_carryforward
      total_debt
      net_debt
      net_interest_expense
      pension_assets
      pension_obligations
      gross_ppe
      net_ppe
      capital_expenditures
      capital_gains
      land
      buildings
    }
  }
`;
