import gql from "graphql-tag";

export default gql`
  query getDocumentLinkedByToken($token_type: String!, $token: String!, $query: QueryInput, $offset: OffsetInput) {
    getDocumentLinkedByToken(token_type: $token_type, token: $token, query: $query, offset: $offset) {
      __typename
      document_link_id
      document_id
      link_type
      link_type_id
      cloud_path
      sha_256_hash
      upload_name
      normalized_name
    }
  }
`;
