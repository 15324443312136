import gql from "graphql-tag";

export default gql`
  query getEarningsRelease($query: QueryInput, $offset: OffsetInput) {
    getEarningsRelease(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      report_period
      report_date
      report_time
      call_date
      call_time
      call_number
      call_pin
      replay_number
      replay_pin
      replay_until
    }
  }
`;
