import gql from "graphql-tag";

export default gql`
  query getSentiment($query: QueryInput, $offset: OffsetInput) {
    getSentiment(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_date
      news_publication_heat
      news_readership_heat
      news_sentiment_score
      news_positive_count
      news_neutral_count
      news_negative_count
      twitter_sentiment_score
      twitter_positive_count
      twitter_neutral_count
      twitter_negative_count
    }
  }
`;
