import gql from "graphql-tag";

export default gql`
  mutation deleteCrmIntermediary($id: String!) {
    deleteCrmIntermediary(id: $id) {
      __typename
      id
      name
      type
      user
    }
  }
`;
