import gql from "graphql-tag";

export default gql`
  query getOwlBot(
    $query: String!
    $doc_sources: [String]
    $files: [String]
    $urls: [String]
    $llm_model: String
    $tokens: Int
    $temperature: Float
  ) {
    getOwlBot(
      query: $query
      doc_sources: $doc_sources
      files: $files
      urls: $urls
      llm_model: $llm_model
      tokens: $tokens
      temperature: $temperature
    )
  }
`;
