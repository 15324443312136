import gql from "graphql-tag";

export default gql`
  mutation addValuation($property_id: String!, $date: String!, $cap_rate: String, $notes: String) {
    addValuation(property_id: $property_id, date: $date, cap_rate: $cap_rate, notes: $notes) {
      __typename
      id
      property_id
      date
      cap_rate
      notes
    }
  }
`;
