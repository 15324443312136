import DoneIcon from "@mui/icons-material/Done";
import React, { useRef, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const ValueContainer = ({ children, getValue, ...props }) => {
  let selectLength = getValue().length;
  let optionsMatch = JSON.stringify(props.selectProps.options).match(/"value"/g);
  let optionsLength = optionsMatch ? optionsMatch.length : 0;

  // Check if values are equal to options and display `All` instead of number.
  let selectText;
  if (selectLength === 0) {
    selectText = props.selectProps.placeholder || "No Filter";
  } else {
    if (props.selectProps.selectText) {
      selectText = props.selectProps.selectText;
    } else {
      selectText =
        selectLength === optionsLength
          ? "All Items Selected"
          : `${selectLength} Item${selectLength !== 1 ? "s" : ""} Selected`;
    }
  }

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && selectText}
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DoneIcon style={{ height: "20px", width: "20px" }} />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <components.CrossIcon />
    </components.ClearIndicator>
  );
};

const isAgGridCellEditorMixin = css`
  &.advanced-select-container {
    font: inherit;
  }
  .advanced-select__control {
    min-height: auto;
    border: none;
  }
  .advanced-select__control:hover {
    border: none;
  }
  .advanced-select__indicator {
    padding: 3px;
    color: rgba(0, 0, 0, 0.65);
  }
  .advanced-select__indicator-seperator {
    padding-top: 5px;
    padding-bottom: 5px;
    height: 20px;
    color: rgba(0, 0, 0, 0.65);
  }
  .advanced-select__value-container {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .advanced-select__menu {
    margin-top: 1px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .advanced-select__option {
    padding: 2px 12px;
  }
`;

const StyledAdvancedSelect = styled(Select)`
  &.advanced-select-container {
    font-size: 16px;
  }
  .advanced-select__control {
    background-color: inherit;
    min-height: 32px;
    cursor: pointer;
  }
  .advanced-select__control--is-focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .advanced-select__control:hover {
    border: #40a9ff solid 1px;
  }
  .advanced-select__value-container {
    color: ${(props) => props.theme.palette.text.secondary};
    padding: 0 8px 0 11px;
    line-height: 28px;
    margin-top: 2px;
  }
  .advanced-select__value-container--is-multi {
    display: flex;
  }
  .advanced-select__value-container--has-value {
    color: ${(props) => props.theme.palette.text.primary};
  }
  .advanced-select__input-container {
    margin: 0;
    padding: 0;
  }
  .advanced-select__option {
    color: ${(props) => props.theme.palette.text.primary};
  }
  .advanced-select__option:hover {
    background-color: ${(props) => props.theme.palette.primary[100]};
  }
  .advanced-select__option--is-selected,
  .advanced-select__option--is-selected.advanced-select__option--is-focused {
    background-color: ${(props) => props.theme.palette.primary[200]};
  }
  .advanced-select__option--is-focused {
    /* background-color: inherit; */
    background-color: ${(props) => props.theme.palette.primary[100]};
  }
  .advanced-select__option--is-focused:active {
    background-color: ${(props) => props.theme.palette.primary[300]};
  }
  .advanced-select__indicator-seperator {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .advanced-select__indicator {
    padding: 4px;
  }

  ${(props) => props.isAgGridCellEditor && isAgGridCellEditorMixin};
`;

const StyledAdvancedCreatableSelect = styled(CreatableSelect)`
  &.advanced-select-container {
    font-size: 16px;
  }
  &.advanced-select-container.new .advanced-select__control::after {
    content: "NEW";
    position: absolute;
    right: 56px;
    font-size: 10px;
    font-weight: bold;
    color: #f5085c;
  }
  .advanced-select__control {
    background-color: inherit;
    min-height: 32px;
    cursor: pointer;
  }
  .advanced-select__control--is-focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .advanced-select__control:hover {
    border: #40a9ff solid 1px;
  }
  .advanced-select__value-container {
    color: ${(props) => props.theme.palette.text.secondary};
    padding: 0 8px 0 11px;
    line-height: 28px;
    margin-top: 2px;
  }
  .advanced-select__value-container--is-multi {
    display: flex;
  }
  .advanced-select__value-container--has-value {
    color: ${(props) => props.theme.palette.text.primary};
  }
  .advanced-select__input-container {
    margin: 0;
    padding: 0;
  }
  .advanced-select__option {
    color: ${(props) => props.theme.palette.text.primary};
  }
  .advanced-select__option:hover {
    background-color: ${(props) => props.theme.palette.primary[100]};
  }
  .advanced-select__option--is-selected,
  .advanced-select__option--is-selected.advanced-select__option--is-focused {
    background-color: ${(props) => props.theme.palette.primary[200]};
  }
  .advanced-select__option--is-focused {
    /* background-color: inherit; */
    background-color: ${(props) => props.theme.palette.primary[100]};
  }
  .advanced-select__option--is-focused:active {
    background-color: ${(props) => props.theme.palette.primary[300]};
  }
  .advanced-select__indicator-seperator {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .advanced-select__indicator {
    padding: 4px;
  }

  ${(props) => props.isAgGridCellEditor && isAgGridCellEditorMixin};
`;

const AdvancedSelect = ({ ...props }) => {
  const selectRef = useRef(null);

  const handleOnChange = (event, action) => {
    if (props.isAgGridCellEditor) {
      if (action.action === "clear") {
        if (props.doneCallback) props.doneCallback("cleared");
      }
    }
    setIsNew(event?.__isNew__);
    if (props.onChange) props.onChange(event);
  };

  const handleMenuClose = () => {
    if (props.doneCallback) props.doneCallback("closed");
  };

  const handleKeyDown = (evt) => {
    switch (evt.key) {
      case "Home":
        evt.preventDefault();
        if (evt.shiftKey) evt.target.selectionStart = 0;
        else evt.target.setSelectionRange(0, 0);
        break;
      case "End":
        evt.preventDefault();
        const len = evt.target.value.length;
        if (evt.shiftKey) evt.target.selectionEnd = len;
        else evt.target.setSelectionRange(len, len);
        break;
      default:
        break;
    }
  };

  let components = {};
  if (props.condenseValueDisplay) components.ValueContainer = ValueContainer;
  if (props.isAgGridCellEditor) components.DropdownIndicator = DropdownIndicator;
  if (props.isAgGridCellEditor) components.ClearIndicator = ClearIndicator;

  const [isNew, setIsNew] = useState(false);

  if (props.creatable) {
    return (
      <StyledAdvancedCreatableSelect
        ref={selectRef}
        components={components}
        onMenuClose={handleMenuClose}
        hideSelectedOptions={false}
        // Think better UX if depends on isMulti
        closeMenuOnSelect={props.isMulti ? false : true}
        blurInputOnSelect={props.isMulti ? false : true}
        menuPlacement="auto"
        placeholder="Select or type new..."
        className={`${["advanced-select-container", props.extraClassName].join(" ")} ${isNew ? "new" : ""}`}
        classNamePrefix="advanced-select"
        menuPortalTarget={props.isAgGridCellEditor ? null : document.body}
        onKeyDown={handleKeyDown}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (base) => ({
            ...base,
            width: "max-content",
            minWidth: "100%",
          }),
          // control: (base) => ({
          //   ...base,
          //   minWidth: "200px",
          // }),
          input: (base) => ({ ...base, paddingTop: 2, paddingBottom: 2 }),
          option: (base, { data }) => ({
            ...base,
            fontSize: props.optionsFontSize || "14px",
            fontStyle: data.isNullOption ? "italic" : null,
            color: data.isNullOption ? "#F50057" : null,
            // ":before": { ...base[":before"], content: data.isNullOption ? '"-- "' : null },
            // ":after": { ...base[":after"], content: data.isNullOption ? '" --"' : null }
          }),
        }}
        {...props}
        isSearchable={true} // Creatable requires searchable
        onChange={handleOnChange}
      />
    );
  } else {
    return (
      <StyledAdvancedSelect
        ref={selectRef}
        components={components}
        onMenuClose={handleMenuClose}
        hideSelectedOptions={false}
        // Think better UX if depends on isMulti
        closeMenuOnSelect={props.isMulti ? false : true}
        blurInputOnSelect={props.isMulti ? false : true}
        menuPlacement="auto"
        isSearchable={false}
        placeholder="Select..."
        className={["advanced-select-container", props.extraClassName].join(" ")}
        classNamePrefix="advanced-select"
        menuPortalTarget={props.isAgGridCellEditor ? null : document.body}
        onKeyDown={handleKeyDown}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (base) => ({
            ...base,
            width: "max-content",
            minWidth: "100%",
          }),
          // control: (base) => ({
          //   ...base,
          //   minWidth: "200px",
          // }),
          input: (base) => ({ ...base, paddingTop: 2, paddingBottom: 2 }),
          option: (base, { data }) => ({
            ...base,
            fontSize: props.optionsFontSize || "14px",
            fontStyle: data.isNullOption ? "italic" : null,
            color: data.isNullOption ? "#F50057" : null,
            // ":before": { ...base[":before"], content: data.isNullOption ? '"-- "' : null },
            // ":after": { ...base[":after"], content: data.isNullOption ? '" --"' : null }
          }),
        }}
        {...props}
        onChange={handleOnChange}
      />
    );
  }
};

export default AdvancedSelect;
