import gql from "graphql-tag";

export default gql`
  query getValuation($query: QueryInput, $offset: OffsetInput) {
    getValuation(query: $query, offset: $offset) {
      __typename
      id
      property_id
      date
      cap_rate
      notes
    }
  }
`;
