import gql from "graphql-tag";

export default gql`
  query getTrade($query: QueryInput, $offset: OffsetInput) {
    getTrade(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_date
      price
      volume
      shares_outstanding
    }
  }
`;
