import { Button, Typography } from "@mui/material";
import React, { Suspense } from "react";
import styled from "@emotion/styled";

const StyledMessage = styled.div`
  &.message {
    padding: 80px 0;
    text-align: center;
  }

  &.message div {
    padding-top: 20px;
  }
`;

const handleReload = () => {
  window.location.reload();
  // window.location.href = window.location.href;
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        // // TODO: re-create the timeout message that react-loadable had (was at 10s)
        //   <StyledMessage className="message">
        //   <Typography variant="h6" color="textPrimary">
        //     Taking longer than expected...
        //   </Typography>
        //   <Button variant="contained" color="primary" size="large" onClick={props.retry}>
        //     Retry
        //   </Button>
        // </StyledMessage>
        <StyledMessage className="message">
          <Typography variant="h6" color="textPrimary">
            Error!
          </Typography>
          <Button variant="contained" color="primary" size="large" onClick={handleReload}>
            Retry
          </Button>
        </StyledMessage>
      );
    }

    return this.props.children;
  }
}

const LazyHOC = (LazyComponent) => {
  return (props) => (
    <ErrorBoundary>
      <Suspense
        fallback={
          <StyledMessage className="message">
            <Typography variant="h6" color="textPrimary">
              Loading...
            </Typography>
          </StyledMessage>
        }
      >
        <LazyComponent {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default LazyHOC;
