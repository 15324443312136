import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import configEnv from "./environment";

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

const paginatedQueryPolicy = (keyArgs = false) => {
  return {
    keyArgs: keyArgs,
    merge(existing, incoming, options) {
      let args = options.args;
      if (!args?.offset) {
        // console.log("MERGE PROBLEMS.... NO OFFSET!!!");
        return incoming;
      } else {
        // Slicing is necessary because the existing data is
        // immutable, and frozen in development.
        const merged = existing ? existing.slice(0) : [];
        for (let i = 0; i < incoming.length; ++i) {
          merged[args.offset.start - 1 + i] = incoming[i];
        }
        return merged;
      }
    },
  };
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      // Remove offsetInput from keyArgs (everything in api with OffsetInput)
      fields: {
        // Common
        getEntity: paginatedQueryPolicy(["query"]),
        getSecurity: paginatedQueryPolicy(["query"]),
        getSecurityProxy: paginatedQueryPolicy(["query"]),
        getTableUpdateTime: { keyArgs: ["tables"] },
        // Credit
        getRatingDefaultRate: paginatedQueryPolicy(["query"]),
        getCreditDaily: paginatedQueryPolicy(["query"]),
        getCreditQuarterly: paginatedQueryPolicy(["query"]),
        getRatingDetail: paginatedQueryPolicy(["query"]),
        getEarningsRelease: paginatedQueryPolicy(["query"]),
        getSentiment: paginatedQueryPolicy(["query"]),
        getPeer: paginatedQueryPolicy(["query"]),
        getSupplyChain: paginatedQueryPolicy(["query"]),
        getHolder: paginatedQueryPolicy(["query"]),
        getBusinessSegment: paginatedQueryPolicy(["query"]),
        getOptimizedCreditComposite: paginatedQueryPolicy(["query"]),
        // Market
        getFundamental: paginatedQueryPolicy(["query"]),
        getFundamentalSignal: paginatedQueryPolicy(["query"]),
        getTrade: paginatedQueryPolicy(["query"]),
        getTradeSignal: paginatedQueryPolicy(["query"]),
        getRating: paginatedQueryPolicy(["query"]),
        getDebt: paginatedQueryPolicy(["query"]),
        getEstimate: paginatedQueryPolicy(["query"]),
        getAnnouncement: paginatedQueryPolicy(["query"]),
        getESG: paginatedQueryPolicy(["query"]),
        getScoring: paginatedQueryPolicy(["query"]),
        getCatalyst: paginatedQueryPolicy(["query"]),
        getOptimizedComposite: paginatedQueryPolicy(["query"]),
        // News
        getNews: paginatedQueryPolicy(["query"]),
        // Portfolio
        getProperty: paginatedQueryPolicy(["query"]),
        getPurchase: paginatedQueryPolicy(["query"]),
        getValuation: paginatedQueryPolicy(["query"]),
        getSale: paginatedQueryPolicy(["query"]),
        getFunding: paginatedQueryPolicy(["query"]),
        getLease: paginatedQueryPolicy(["query"]),
        getRent: paginatedQueryPolicy(["query"]),
        getDueDiligence: paginatedQueryPolicy(["query"]),
        getAudit: paginatedQueryPolicy(["query"]),
        // Oak
        getUser: paginatedQueryPolicy(["query"]),
        getTelemetry: paginatedQueryPolicy(["query"]),
        getCustomFilter: paginatedQueryPolicy(["query"]),
        getDocumentLinked: paginatedQueryPolicy(["query"]),
        getDocumentLinkedByToken: paginatedQueryPolicy(["token_type", "token", "query"]),
        getDocumentLinkedByEntity: paginatedQueryPolicy(["link_type", "link_type_id", "query"]),
        getCrmIntermediary: paginatedQueryPolicy(["query"]),
        getCrmPerson: paginatedQueryPolicy(["query"]),
        getCrmActivityLinked: paginatedQueryPolicy(["query"]),
        getCrmActivityLinkedByEntity: paginatedQueryPolicy(["crm_type", "crm_type_id", "query"]),
        getCrmStatus: paginatedQueryPolicy(["query"]),
        getCreditCommentary: paginatedQueryPolicy(["query"]),
        getCreditCommentaryByEntity: paginatedQueryPolicy(["security_id", "query"]),
        getPipeline: paginatedQueryPolicy(["query"]),
        getTenantTask: paginatedQueryPolicy(["query"]),
        getTenantTaskByToken: paginatedQueryPolicy(["token", "query"]),
        getTenantTaskStatus: paginatedQueryPolicy(["query"]),
        getTenantTaskStatusByToken: paginatedQueryPolicy(["token", "query"]),
      },
    },
    LuEGics: { keyFields: ["code"] },
    LuGeo: { keyFields: ["code"] },
    LuNaics: { keyFields: ["code"] },
    LuOakEntity: { keyFields: ["code"] },
    LuOakTranche: { keyFields: ["code"] },
    LuPsa: { keyFields: ["code"] },
    LuRating: { keyFields: ["code"] },
    OC: { keyFields: ["e_id"] },
    OCC: { keyFields: ["e_id"] },
    DocumentLinked: { keyFields: ["document_link_id"] },
    CrmActivityLinked: { keyFields: ["crm_activity_link_id"] },
    CrmStatus: { keyFields: ["crm_type", "crm_type_id"] },
  },
});

const httpLink = createHttpLink({
  uri: configEnv.GRAPHQL_URL,
});

const authLink = setContext(async (_, { headers }) => {
  let token; // authenticated user
  let unauthenticatedHeaders = { "x-api-key": configEnv.API_KEY }; // unauthenticated (guest) user

  try {
    const activeAccount = msalInstance.getActiveAccount();
    let authResult = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read"],
      account: activeAccount,
    });
    // console.log("CURERNT AUTH");
    // console.log(authResult.idToken);
    // console.log("STALENESS: ", authResult.idTokenClaims.exp - Date.now() / 1000);
    const stale = authResult.idTokenClaims.exp - Date.now() / 1000 < 300;
    if (stale) {
      // console.log("REFRESH TOKEN");
      authResult = await msalInstance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: activeAccount,
        forceRefresh: true,
      });
      // console.log("NEW AUTH");
      // console.log(authResult.idToken);
    }
    token = authResult.idToken;
  } catch (error) {
    // no-op, this catches a thrown error: no current user
  }

  const authHeaders = token ? { authorization: token } : unauthenticatedHeaders;

  return {
    headers: {
      // ...headers,
      ...authHeaders,
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
});

const app = document.getElementById("root");
const root = createRoot(app);
root.render(
  <MsalProvider instance={msalInstance}>
    <ApolloProvider client={apolloClient}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </MsalProvider>
);

serviceWorker.unregister();
