import gql from "graphql-tag";

export default gql`
  query getCreditCommentary($query: QueryInput, $offset: OffsetInput) {
    getCreditCommentary(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_time
      category
      detail
      current
      user
    }
  }
`;
