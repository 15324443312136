import gql from "graphql-tag";

export default gql`
  query getOptimizedComposite($query: QueryInput, $offset: OffsetInput) {
    getOptimizedComposite(query: $query, offset: $offset) {
      __typename
      e_id
      e_lei
      e_typ
      e_pnt
      e_sid
      e_spk
      e_sn
      e_ln
      e_des
      e_rf
      e_rc
      e_rp
      e_ceo
      e_ceod
      e_cfo
      e_cfod
      e_url
      e_irn
      e_irt
      e_ire
      e_irp
      e_egic
      e_sec
      e_igp
      e_ind
      e_subi
      e_geo
      e_ct
      e_st
      e_cty
      r_am
      r_bbg
      r_db
      r_ej
      r_fch
      r_kb
      r_md
      r_sp
      f_mc
      f_gppe
      f_nppe
      f_lnd
      f_bld
      c_scr
      c_rat
      c_val
      c_px
      c_re
      c_mg
      c_ma
      c_act
      c_buy
      c_db
      c_rev
      c_cpx
      c_pen
      c_int
      c_nol
      c_tax
      s_slb
      s_re
      s_val
      s_int
      s_cpx
      s_act
      s_rev
      s_ma
      s_mg
      s_nws
      a_st
      a_at
      a_tp
      a_ltt
      a_lta
      a_ltd
      a_ltu
      a_lutt
      a_luta
      a_lutd
      a_lutu
    }
  }
`;
