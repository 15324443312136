import gql from "graphql-tag";

export default gql`
  query getNews($query: QueryInput, $offset: OffsetInput) {
    getNews(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_time
      source
      type
      relevance
      headline
      summary
      topics
      sentiment
      url
      image_url
      lang
      paywall
    }
  }
`;
