import gql from "graphql-tag";

export default gql`
  query getUniverse($entityTypes: [EntityTypeEnum!]!) {
    getUniverse(entityTypes: $entityTypes) {
      __typename
      id
      parsekey
      short_name
      entity_type
      egics_code
      geo_code
    }
  }
`;
