import gql from "graphql-tag";

export default gql`
  mutation deleteDocumentLink($id: String!) {
    deleteDocumentLink(id: $id) {
      __typename
      document_link_id
      document_id
      link_type
      link_type_id
      cloud_path
      sha_256_hash
      upload_name
      normalized_name
    }
  }
`;
