import gql from "graphql-tag";

export default gql`
  mutation updatePurchase($id: String!, $updates: [UpdateInput!]!) {
    updatePurchase(id: $id, updates: $updates) {
      __typename
      id
      property_id
      asset_mgmt_id
      oak_entity_code
      oak_tranche_code
      status
      date
      currency
      price
      price_usd
      closing_costs
      noi
      source
      competition
      sourced_by
      market_rent_psf
      tags
      notes
    }
  }
`;
