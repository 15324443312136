import gql from "graphql-tag";

export default gql`
  query getTradeSignal($query: QueryInput, $offset: OffsetInput) {
    getTradeSignal(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      effective_date
      momentum_1m
      momentum_3m
      momentum_12m
    }
  }
`;
