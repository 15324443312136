import gql from "graphql-tag";

export default gql`
  query getSupplyChain($query: QueryInput, $offset: OffsetInput) {
    getSupplyChain(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      security_id_related
      security_parsekey_related
      relationship_type
      relationship_value
      revenue_percent
      cost_type
      cost_percent
      data_source
      relationship_period
      as_of_date
    }
  }
`;
