import gql from "graphql-tag";

export default gql`
  query getBusinessSegment($query: QueryInput, $offset: OffsetInput) {
    getBusinessSegment(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      type
      name
      level
      display_order
      path
      revenue
      gross_profit
      ebitda
      net_income
    }
  }
`;
