import gql from "graphql-tag";

export default gql`
  mutation addAudit(
    $user: String!
    $table: String!
    $primary_key: String!
    $field: String!
    $value: String
    $notes: String
  ) {
    addAudit(user: $user, table: $table, primary_key: $primary_key, field: $field, value: $value, notes: $notes) {
      __typename
      id
      user
      table
      primary_key
      field
      value
      notes
    }
  }
`;
