import gql from "graphql-tag";

export default gql`
  mutation addCustomFilter($user_id: String, $type: String!, $name: String!, $definition: String!) {
    addCustomFilter(user_id: $user_id, type: $type, name: $name, definition: $definition) {
      __typename
      id
      user_id
      type
      name
      definition
    }
  }
`;
