import gql from "graphql-tag";

export default gql`
  query getCreditQuarterly($query: QueryInput, $offset: OffsetInput) {
    getCreditQuarterly(query: $query, offset: $offset) {
      __typename
      id
      security_id
      security_parsekey
      year
      quarter
      altman_z
      altman_z_p
      altman_z_pp
      wacc
      wacc_debt
      wacc_equity
      sales_growth_5y
      return_on_common_equity
      return_on_assets
      net_interest_margin
      efficiency_ratio
      total_loans_to_total_deposits
      total_loans_to_total_assets
      liquid_assests_to_total_assets
      deposits_to_funding
      deposit_growth_1y
      texas_ratio
      loan_loss_reserve_to_total_loans
      npls_to_total_loans
      loan_loss_reserve_to_npls
      loan_loss_prov_to_pretax_preprov_profit
      leverage_ratio
      tier1_common_equity_ratio
      tier1_risk_capital_ratio
      total_risk_capital_ratio
      gross_margin
      operating_margin
      ebitda_margin
      free_cash_flow_margin
      free_cash_flow_to_total_debt
      current_ratio
      cash_ratio
      cash_conversion_cycle
      total_debt_to_ebitda
      net_debt_to_ebitda
      total_debt_to_total_capital
      total_debt_to_total_equity
      ebitda_to_total_interest
      inventory_to_working_capital
      asset_turnover
      capital_expenditures_to_sales
    }
  }
`;
