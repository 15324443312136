import gql from "graphql-tag";

export default gql`
  mutation deleteCrmPerson($id: String!) {
    deleteCrmPerson(id: $id) {
      __typename
      id
      first_name
      last_name
      title
      affiliation
      email
      office_phone
      mobile_phone
      asset_focus
      coverage_focus
      user
    }
  }
`;
