import gql from "graphql-tag";

export default gql`
  query getLuOakEntity {
    getLuOakEntity {
      __typename
      code
      code_parent
      name
      level
      description
      path
      depth
    }
  }
`;
