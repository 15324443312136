import gql from "graphql-tag";

export default gql`
  query getTenantTaskStatusByToken($token: String!, $query: QueryInput, $offset: OffsetInput) {
    getTenantTaskStatusByToken(token: $token, query: $query, offset: $offset) {
      __typename
      id
      tenant_task_id
      effective_period
      due_date
      complete_date
      complete_docs
      complete_note
      review_date
      review_status
      review_note
      review_user
    }
  }
`;
