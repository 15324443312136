import gql from "graphql-tag";

export default gql`
  mutation addTempEntity(
    $entity_type: String!
    $short_name: String!
    $long_name: String!
    $egics_code: String!
    $geo_code: String!
  ) {
    addTempEntity(
      entity_type: $entity_type
      short_name: $short_name
      long_name: $long_name
      egics_code: $egics_code
      geo_code: $geo_code
    ) {
      __typename
      id
      entity_type
      id_ultimate_parent
      security_id_reporting
      security_parsekey_reporting
      short_name
      long_name
      description
      reporting_format
      reporting_currency
      reporting_periodicity
      ceo
      ceo_start_date
      cfo
      cfo_start_date
      exec_officers
      website
      ir_name
      ir_title
      ir_email
      ir_phone
      egics_code
      sector
      industry_group
      industry
      sub_industry
      geo_code
      country
      state
      city
    }
  }
`;
