import gql from "graphql-tag";

export default gql`
  query getCrmPerson($query: QueryInput, $offset: OffsetInput) {
    getCrmPerson(query: $query, offset: $offset) {
      __typename
      id
      first_name
      last_name
      title
      affiliation
      email
      office_phone
      mobile_phone
      asset_focus
      coverage_focus
      user
    }
  }
`;
