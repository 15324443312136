import gql from "graphql-tag";

export default gql`
  mutation addSale(
    $purchase_id: String!
    $property_id: String!
    $oak_entity_code: String
    $oak_tranche_code: String
    $status: String
    $date: String
    $currency: String
    $price: String
    $price_usd: String
    $closing_costs: String
    $broker_fees: String
    $noi_loi: String
    $noi_loi_ftm: String
    $noi_closing: String
    $noi_closing_ftm: String
    $buyer: String
    $buyer_type: String
    $gross_moic: String
    $gross_irr: String
    $net_moic: String
    $net_irr: String
    $gross_moic_ex_swaps: String
    $gross_irr_ex_swaps: String
    $net_moic_ex_swaps: String
    $net_irr_ex_swaps: String
    $tags: String
    $notes: String
  ) {
    addSale(
      purchase_id: $purchase_id
      property_id: $property_id
      oak_entity_code: $oak_entity_code
      oak_tranche_code: $oak_tranche_code
      status: $status
      date: $date
      currency: $currency
      price: $price
      price_usd: $price_usd
      closing_costs: $closing_costs
      broker_fees: $broker_fees
      noi_loi: $noi_loi
      noi_loi_ftm: $noi_loi_ftm
      noi_closing: $noi_closing
      noi_closing_ftm: $noi_closing_ftm
      buyer: $buyer
      buyer_type: $buyer_type
      gross_moic: $gross_moic
      gross_irr: $gross_irr
      net_moic: $net_moic
      net_irr: $net_irr
      gross_moic_ex_swaps: $gross_moic_ex_swaps
      gross_irr_ex_swaps: $gross_irr_ex_swaps
      net_moic_ex_swaps: $net_moic_ex_swaps
      net_irr_ex_swaps: $net_irr_ex_swaps
      tags: $tags
      notes: $notes
    ) {
      __typename
      id
      purchase_id
      property_id
      oak_entity_code
      oak_tranche_code
      status
      date
      currency
      price
      price_usd
      closing_costs
      broker_fees
      noi_loi
      noi_loi_ftm
      noi_closing
      noi_closing_ftm
      buyer
      buyer_type
      gross_moic
      gross_irr
      net_moic
      net_irr
      gross_moic_ex_swaps
      gross_irr_ex_swaps
      net_moic_ex_swaps
      net_irr_ex_swaps
      tags
      notes
    }
  }
`;
